<template>
  <div class="all">
    <div class="header">
      <div class="left" @click="goBack">
        <el-icon><ArrowLeftBold /></el-icon>
      </div>

      <div @click.stop="goOnlineExpert">
        <img style="width: 60px; height: 40px" src="@/assets/footerImg.png" alt="" />
      </div>
      <div style="width: 40px; height: 40px"></div>
    </div>

    <div class="search">
      <el-input
        v-model="search"
        class="w-50 m-2"
        size="large"
        placeholder="Please Input"
        :suffix-icon="Search"
      />
    </div>

    <div class="tips">
      <div>
        <span>{{ store.gwMsgArr.sumUnread ? store.gwMsgArr.sumUnread : "0" }}</span>
        <span>Unread</span>
      </div>

      <div>
        <!-- <span>6</span>
        <span>Mailbox</span> -->
      </div>
    </div>

    <!-- 列表 -->
    <div class="list">
      <div
        v-for="item in upList"
        :key="item.id"
        class="list-word"
        @click="msgDetail(item)"
      >
        <div class="left">
          <el-avatar
            :size="70"
            :src="
              item.avatar || item.avatarImage
                ? ImageUrl + (item.avatar || item.avatarImage)
                : require('@/assets/default-user.png')
            "
          />
        </div>
        <!-- :value='item.msgNum ? item.msgNum : ""' -->
        <el-badge
          :value="item.msgNum ? item.msgNum : ''"
          :max="99"
          class="item"
        ></el-badge>

        <div class="middle">
          <div class="user">
            <div class="name">
              {{ item.nickname }}
            </div>
            <div class="aboutMe">
              {{ item.aboutMyService }}
            </div>
          </div>
        </div>

        <div class="right"></div>

        <div class="goIn">
          <el-icon><ArrowRightBold /></el-icon>
        </div>
      </div>
    </div>

    <!-- 收藏列表 -->
    <div v-if="!search && favoriteList.length > 0">
      <div style="padding: 5px 15px">
        <h5>Favorites list</h5>
      </div>
      <div class="list">
        <div
          v-for="item in favoriteList"
          :key="item.id"
          class="list-word"
          @click="msgDetail(item)"
        >
          <div class="left">
            <el-avatar
              :size="70"
              :src="
                item.avatarImage
                  ? ImageUrl + item.avatarImage
                  : require('@/assets/default-user.png')
              "
            />
          </div>
          <!-- :value='item.msgNum ? item.msgNum : ""' -->
          <el-badge :value="item.msgNum ? item.msgNum : ''" class="item"></el-badge>

          <div class="middle">
            <div class="user">
              <div class="name">
                {{ item.nickname }}
              </div>
              <div class="aboutMe">
                {{ item.aboutMyService }}
              </div>
            </div>
          </div>
          <div class="goIn">
            <el-icon><ArrowRightBold /></el-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- 推荐列表 -->
    <!-- <div v-if="!search && data.listArr.length > 0">
      <div style="padding: 5px 15px">
        <h5>Hot list</h5>
      </div>
      <div class="list">
        <div
          v-for="item in data.listArr"
          :key="item.id"
          class="list-word"
          @click="msgDetail(item)"
        >
          <div class="left">
            <el-avatar
              :size="70"
              :src="
                item.avatarImage
                  ? ImageUrl + item.avatarImage
                  : require('@/assets/default-user.png')
              "
            />
          </div>

          <div class="middle">
            <div class="user">
              <div class="name">
                {{ item.nickname }}
              </div>
              <div class="aboutMe">
                {{ item.aboutMyService }}
              </div>
            </div>
          </div>

          <div class="goIn">
            <el-icon><ArrowRightBold /></el-icon>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { Search, Clock, ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
import {
  ref,
  defineProps,
  watch,
  inject,
  onMounted,
  nextTick,
  onUnmounted,
  watchEffect,
  reactive,
} from "vue";
import request from "@/utils/request.js";
import { sendWsMsg } from "@/api/messageCall";
import ImageUpload from "@/components/ImageUpload/index.vue";
import { ElMessage } from "element-plus";
import { getType } from "@/utils/auth";
import bus from "vue3-eventbus";
import { phoneStore } from "../../store/phone1";
import { useRouter, useRoute } from "vue-router";
const props = defineProps({
  showModelVal: Boolean,
  propNickName: String,
});
const socket = inject("socket");
const messageImgVal = ref("");
const search = ref("");
const messageVal = ref("");
const showModel = ref(false);
const liActive = ref(null);
const liActiveid = ref(null);
const messageList = ref([]);
const msglist = ref("");
const tuijianList = ref([]);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const liActiveTJ = ref(null);
const liActiveidTJ = ref(null);
const upList = ref([]);
const callTimes = ref(null);
const callLoading = ref(false);
const openid = ref("");
const store = phoneStore();
const router = useRouter();
const route = useRoute();

const pageNum = ref(1);
const pageSize = ref(10);

const favoriteList = ref([]);

const data = reactive({
  listArr: [],
});

onMounted(() => {
  console.log(route.query.type, "地址栏信息");
  if (route.query.type == "1") {
    bus.on("receiveGw", () => getUpList());
    getUpList();
    console.log("顾问的信息详情");
  } else {
    bus.on("receiveYh", () => searchList());
    getFavorite();
    getLiveList();
    searchList();
  }
});

watch(search, () => {
  if (route.query.type == "1") {
    searchLis2();
  } else {
    searchList();
  }
});

//顾问页面的用户列表
async function getUpList() {
  /* &isRecommend=true  &hasChat=true */
  const { code, rows } = await request.get(
    "/system/FaUser/web/list?pageSize=10000&hasChat=true"
  );
  if (code == 200) {
    upList.value = rows;
    console.log(rows, "upList.value");
  }
  // console.log(rows);
}

async function searchLis2() {
  const { code, rows } = await request.get(
    `/system/FaUser/web/list?pageSize=10000&hasChat=true${
      search.value ? "&nickname=" + search.value : ""
    }`
  );
  if (code == 200) {
    upList.value = rows;
    console.log(upList.value, "upList.value");
  }
  console.log(rows);
}

//去个人聊天详情
const msgDetail = (obj) => {
  console.log(obj, "obj");
  if (route.query.type == "1") {
    router.push({
      path: "/msgDetail/" + obj.id,
      query: { ...obj, type: "1" },
    });
    return;
  }
  router.push({
    path: "/msgDetail/" + obj.id,
    query: obj,
  });
};

//返回上页
const goBack = () => {
  if (route.query && route.query.type == "1") {
    router.push("/profileInfo/home");
    return;
  }
  console.log("11");
  router.push("/onlineExpert");
};
//去首页
const goOnlineExpert = () => {
  if (route.path == "/onlineExpert") {
    router.go(0);
  } else {
    router.push("/onlineExpert");
  }
};

//搜索列表
async function searchList() {
  console.log("1111");
  const { code, rows } = await request.get(
    `/system/faConsultant/list?pageSize=10000${
      search.value ? "&nickname=" + search.value : "&hasChat=" + true
    }`
  );
  if (code == 200) {
    upList.value = rows;
    console.log(upList.value, "upList.value");
  }
}

//收藏列表
async function getFavorite() {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  const { code, rows, total } = await request.get("/system/follower/list", {
    params: params,
  });
  if (code == 200) {
    store.favoriteStoreList = rows;
    let arr = [];
    favoriteList.value = store.favoriteStoreList.sort((a, b) => {
      // 降序排序
      return b.msgNum - a.msgNum;
    });
  }
}

//推荐
async function getLiveList() {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  const { code, rows, total } = await request.get("/system/faConsultant/list?isHot=2", {
    params: params,
  });
  if (code == 200 && pageNum.value <= Math.ceil(total / pageSize.value)) {
    console.log("添加");
    data.listArr = data.listArr.concat(rows);
  }
  // if (pageNum.value >= Math.ceil(total / pageSize.value)) {
  //     showmorecontent.value = 'no more'
  // }
}
</script>

<style lang="scss" scoped>
.all {
  .header {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background-color: skyblue;
    color: #ffffff;
    display: flex;
    justify-content: space-between;

    .left {
      margin-left: 10px;
      width: 30px;
    }
  }

  .search {
    height: 50px;
    width: 100%;
    background-color: yellow;

    .w-50 {
      height: 100%;
    }
  }

  .tips {
    color: var(--l4-footer-color);
    display: flex;
    font-weight: 700;
    font-size: 13px;
    padding: 0 15px;
    height: 26px;
    line-height: 26px;
    justify-content: space-between;
    background-color: #baa3cf;
  }

  .list {
    box-sizing: border-box;
    width: 100%;

    .item {
      position: absolute;
      top: -21px;
      left: 70px;
    }

    .list-word {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 7px 12px;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #eee;
      // transform: translateX(0);
      // transition: margin-left 500ms, opacity 500ms;
      line-height: 74px;
      height: 83px;

      .left {
        width: 80px;
      }

      .right {
        // text-align:right;
        width: 50px;
      }

      .goIn {
        position: absolute;
        right: 10px;
        top: 9px;
      }

      .middle {
        flex: 1;
        width: 65%;

        .user {
          height: 55px;
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          padding: 5px;
          .name {
            font-size: 17px;
            height: 28px;
            line-height: 25px;

            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }

          .aboutMe {
            font-size: 15px;
            height: 25px;
            line-height: 25px;

            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }
    }
  }
}
</style>
